import "lazysizes";
import "picturefill";

//ヘッダーメニュー
$("#header__spBtn").on("click", function () {
  $("html").toggleClass("scroll-prevent");
  if ($(this).hasClass("is-active")) {
    $(this).removeClass("is-active");
    $("#header__spMenu").removeClass("is-active");
  } else {
    $(this).addClass("is-active");
    $("#header__spMenu").addClass("is-active");
  }
});

$(".topCase__list").slick({
  slidesToShow: 1,
  arrows: true,
  dots: true,
  prevArrow: `<div class="slide-arrow prev-arrow"><img src="${path}/img/slide-l.svg" alt=""></div>`,
  nextArrow: `<div class="slide-arrow next-arrow"><img src="${path}/img/slide-r.svg" alt=""></div>`,
});

// 料金表タブ切り替え
$(document).ready(function () {
  $(".topPrice__tab__item").click(function () {
    $(".topPrice__tab__item").removeClass("active");
    $(this).addClass("active");

    var contentId = $(this).data("content");
    $(".topPrice__box").hide();
    $("#" + contentId).show();
  });

  $(".topPrice__tab__item:first").trigger("click");
});

$(function () {
  $(".wpcf7-list-item-label").replaceWith(function () {
    $(this).replaceWith(
      `<p><a href="/privacy" target="_blank">個人情報のお取り扱い</a>` +
        $(this).text() +
        "</p>"
    );
  });
});
